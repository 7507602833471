.Gallery {}

.Gallery ul{
    list-style: none;
    margin: 0 -0.5em;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

}

.Gallery ul li{
    text-align: left;
    padding: 0.5em;
    width: 33.33%;
    opacity: 0.8;
    transition: opacity 0.15s, transform 0.15s ease-in-out;
    transform: scale(1);
}

.Gallery ul li:hover{
    opacity: 1;
    cursor: pointer;
}

.Gallery ul li:active{
    transform: scale(0.98);
}

.Gallery ul li img{
    border: 2px solid white;
    max-width: 300px;
}