.Header {
    position: relative;
    width: 100%;
    height: 50vw;
    padding: 40px;
    background-color: black;
    color: white;
}

@media (orientation: landscape) {
    .Header {
        height: 50vh;
    }
}

.Background{
    position: absolute;
    top: 0px;
    bottom: -100px;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    transform: translateY(0%);
    z-index: 0;
}

.HeaderIn{
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
    transform: translateY(0px);
    opacity: 1;
}

.HeaderOut{
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
    transform: translateY(-100px);
    opacity: 0;
}

.MainHeadingWrapper{
    display: flex;
}

.BreadCrumb{
    margin-top: 0.4em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.8em;
}

.BreadCrumb b{
    font-weight: 800;
}

@media screen and (min-width: 481px) {
  
    .MainHeading h1{
        text-transform: uppercase;
        font-weight: 700;
        margin: 0;
        font-size: 2em;
    }

    .Background{
        background-position: 50% 50%;
    }
}

@media screen and (min-width: 769px) {
}

@media screen and (min-width: 1025px) {
}

@media screen and (min-width: 1200px) {

    .Background{
        top: -100px;
        bottom: -100px;
    }
}

.Header video{
    width: 100%;
    height: 100%;
}