.SectionProgress {
    width: 100%;
    height: 20px;
    border: 3px solid white;
}

.SectionProgress ul{
    list-style: none;
    margin: 0;
    padding: 3px 1px;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.ProgressBlock{
    height: 100%;
    padding: 0 1px;
}

.ProgressBlock::after{
    display: block;
    width: 100%;
    height: 100%;
    content: "";
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

li.Active::after{
    opacity: 1;
    background-color: white;
}