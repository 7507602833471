.Tools {}

.Tools ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

}

.Tools ul li{
    text-align: left;
    padding: 1em 0;
    width: 20%;
}

.Tools ul li img{
    max-width: 40px;
}

.ToolWrapper{
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.ToolWrapper:hover .Tooltip{
    opacity: 1;
}

.Tooltip{
    z-index: 10;
    min-width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    background-color: black;
    opacity: 0;
    font-size: 0.8em;
    white-space: nowrap;
    text-align: center;
    padding: 0.1em 0.5em;
    transition: opacity 0.15s ease-in-out;
    pointer-events: none;
}