.GalleryOverlay {
}

.GalleryOverlay img{
    max-width: 800px;
    height: auto;
    border: 2px solid white;
    background-color: #222;
}

.GalleryOverlay video{
    width: 100%;
    max-width: 800px;
    height: auto;
    border: 2px solid white;
    background-color: #222;
}

.GalleryOverlay p{
    text-align: center;
    margin-top: 1em;
}