@import url('bootstrap/dist/css/bootstrap.min.css');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500,600;700;800&display=swap'); */

html{
  background-color: black;
  position: relative;
}

body {
  margin: 0;
  font-family: Poppins, 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a{
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: white;
}

a:hover{
  color: #ccc;
}

a:visited{
  color: white;
}

.main-heading{
  border: 3px solid white;
  padding: 10px 30px;
  width: fit-content;
}

.main-heading h1{
  text-transform: uppercase;
  font-weight: 700;
  margin: 0;
  font-size: 1.5em;
}

.main-copy-col{
  padding:0;
}

.main-copy{
  background-color: #333;
  color: #ccc;
  padding: 2em 2em 3em;
  margin-bottom: 4em;
}

.secondary-copy{
  color: #999;
}

section{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  height: 100vh;
}

section.landing p{
  margin-top: 0.4em;
  font-size: 1.3em;
}

section.landing b{
  font-weight: 800;
  text-transform: uppercase;
}

section.landing img.logo{
  border: 2px solid;
  max-width: 200px;
}

section.landing img.scroll{
  position: absolute;
  bottom: 2em;
  width: 40px;
  animation: pulse 3s ease-in-out 0s infinite;
}

section.contact-me{
  height: 30vh;
}

section.contact-me .main-heading{
  margin-bottom: 1rem;
}

.SectionWrapper{
  position: relative;
  min-height: 100vh;
  font-size: 1em;
  overflow: hidden;
  transform: scale(1);
  perspective: 2px;
  background-color: white;
}

.SectionWrapper.Dark{
  background-color: #222;
}

.SectionContent{
  position: relative;
  padding: 80px 30px 120px 30px;
  background-color: white;
  color: black;
  bottom: 0px;
  flex-grow: 1;
}

.SectionWrapper.Dark .SectionContent{
  background-color: #222;
  color: white;
}

.SectionContent .Background{
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/public/square4px-space1px.png");
  background-repeat: repeat;
  background-position: center center;
  mix-blend-mode: overlay;
  opacity: 0.4;
}

.VSpacer10px{
  width: 10px;
}

.VSpacer5px{
  width: 5px;
  z-index: -1;
}

img{
  width: 100%;
  height: auto;
}

span.emphasise{
  display: inline-block;
  /*border: 2px solid;*/
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0 0 5px;
  margin-right: 0.5em;
  color: white;
}

h2{
  display: inline-block;
  border: 2px solid;
  font-weight: bold;
  font-size: 0.8em;
  text-transform: uppercase;
  padding: 0 5px;
  color: white;
}

.gallery-overlay {
  position: fixed;
  z-index: 200;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
  justify-content: center;
  align-items: center;
}

.gallery-overlay .section-progress-wrapper{
  position: absolute;
  bottom: 2em;
  width: 100%;
  max-width: 300px;
}

.gallery-overlay-content{
  /* padding: 1em; */
  display: flex;
  width: 100%;
  flex-direction: column;
}

ul.carousel{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  list-style: none;
  margin: 0;
  padding: 0;
  transition: transform 0.5s ease-in-out;
}

ul.carousel li{
  display: flex;
  width: 100vw;
  padding: 1em;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gallery-visible{
  display: flex;
}

.gallery-buttons{
  position: fixed;
  bottom: 50%;
  right: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.gallery-buttons button{
  pointer-events:all;
  color: black;
  border: none;
  background-color: white;
  /* font-weight: 700; */
  font-size: 2em;
  text-transform: uppercase;
  padding: 0 0.5em;
}

@media screen and (min-width: 480px) {
  .SectionContent{
    min-height: 20vh;
  }

  .main-copy-col{
    padding:0;
  }
  
  .row{
    --bs-gutter-x: 4rem;
  }

  section.landing img.logo{
    max-width: 250px;
  }
}

@media screen and (min-width: 769px) {
  .SectionContent{
    /* min-height: 60vh; */
  }

  .main-copy-col{
    padding:0 0 0 2em;
  }
  
  
  .row{
    --bs-gutter-x: 4rem;
  }
}

@media screen and (min-width: 990px) {
  .SectionContent{
    /* min-height: 60vh; */
  }

  .secondary-copy{
    padding-left: 4em;
  }
  
  .row{
    --bs-gutter-x: 4rem;
  }
}

@media screen and (min-width: 1200px) {
  
}

@keyframes pulse {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}